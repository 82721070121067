import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlOptionModel } from '@/model/form-control';
import { QueryPageModel } from '@/model/query-model';
import CommonService from '@/service/common';

export class WatchCameraEntityModel extends BaseEntityModel {
    @FormControl({
        label: '选择镜头',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: CommonService.cameraList
    } as FormControlOptionModel)
    monitorId: string = undefined;

    @FormControl({
        label: '选择分组',
        type: FormControlType.SELECT,
        required: true,
        optionsPromise: CommonService.cameraGroupKv
    } as FormControlOptionModel)
    groupId: string = undefined;
    groupName: string = undefined;

    monitor: string = undefined;
    positionName: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '序号',
                dataIndex: 'no',
                scopedSlots: { customRender: 'no' }
            },
            {
                title: '展示镜头',
                dataIndex: 'monitor'
            },
            {
                title: '分组',
                dataIndex: 'groupName'
            },
            {
                title: '位置',
                dataIndex: 'positionName'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    // toService() {
    //     const data: any = super.toService();
    //     data.groupId = data.groupName;
    //     return data;
    // }
}

export class WatchCameraQueryModel extends QueryPageModel {
    name: string = undefined;
    toService() {
        const data: any = super.toService();
        return data;
    }
}
